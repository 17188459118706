/* components/Header.css */
header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background: linear-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
    color: #111;
  }
  
  header .logo {
    display: flex;
    align-items: center;
    font-weight: 900;
    color: #555;
  }
  
  header .logo span {
    font-family: 'Inter', sans-serif;
    margin-left: 10px;
    font-size: 1.5em;
  }

  .domain {
    font-size: 0.4em;
    letter-spacing: 0.5ch;
    font-weight: 400;
    color: #888;
  }
  
  nav {
    position: relative;
  }
  
  nav ul {
    list-style: none;
    display: flex;
    gap: 30px;
    margin-right: 50px;
  }
  
  nav a {
    color: #333;
    text-decoration: none;
    cursor: pointer;
  }
  
  nav a:hover {
    color: #3985BC;
    text-decoration: underline;
  }
  
  .hamburger {
    display: none;
    flex-direction: column;
    gap: 5px;
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .bar {
    width: 25px;
    height: 3px;
    background-color: #3985BC;
  }

  @media (max-width: 1200px) {
    nav ul {
      gap: 10px;
    }
  }
  
  @media (max-width: 768px) {
    header .logo {
        font-size: 0.6em;
    }

    nav ul {
      display: none;
      flex-direction: column;
      background-color: #FFF;
      position: absolute;
      top: 60px;
      right: 0;
      width: 100%;
      padding: 10px 0;
      border: 1px solid #3985BC;
    }
  
    nav ul li {
      padding: 10px 0;
      text-align: center;
    }
  
    nav.open ul {
      display: flex;
      min-width: 300px; /* Ensure the menu takes the full width */
    }
  
    .hamburger {
      display: flex;
    }
  }
  