/* App.css */
body {
  font-family: 'Roboto Mono', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #FFF;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex: 1;
  padding: 20px;
  width: 80%;
  margin: 0 auto;
  max-width: 1200px
}

h1 {
  font-family: 'Inter', sans-serif;
  font-size: 3em;
  margin-bottom: 10px;
  color: #333;
  letter-spacing: 4px;

}

h2 {
  font-family: 'Inter', sans-serif;
  font-size: 2em;
  margin-bottom: 10px;
  color: #3985BC;
  letter-spacing: 3px;
}


h3 {
  font-family: 'Inter', sans-serif;
  font-size: 1.4em;
  margin-bottom: 10px;
  margin-top: 20px;
  color: #3985BC;
  letter-spacing: 2px;
}

p {
  font-size: 0.9em;
  line-height: 2.3;
  margin: 20px 0px;
}

.bold {
  font-weight: 900;
}

.small {
  font-size: 0.7em;
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  padding: 12px 34px;
  background-color: #3985BC ;
  color: white;
  margin: 5px;
  border: none;
  border-radius: 50px;
  font-size: 15px;
  cursor: pointer;
  text-transform: uppercase;
}

button:hover {
  background: #3d8cc5;
}

.button-alt {
  background: #FFFFFFCC;
  border: 2px solid #3985BC;
  color: #3985BC;
}

.button-alt:hover {
  background: #f5fafd;
}

input[type="text"],
input[type="number"],
select {
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  font-family: Arial, sans-serif;
  transition: border-color 0.3s, box-shadow 0.3s;
}

input[type="text"]:focus,
input[type="number"]:focus,
select:focus {
  border-color: #007BFF;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  outline: none;
}

input[type="text"]:hover,
input[type="number"]:hover,
select:hover {
  border-color: #0056b3;
}

select {
  margin: 5px;
  min-width: 200px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 16px;
  font-family: Arial, sans-serif;
}

table th, table td {
  padding: 12px 15px;
  border: 1px solid #ddd;
  text-align: left;
}

table th {
  background-color: #f4f4f4;
  font-weight: bold;
}

table tr:nth-child(even) {
  background-color: #f9f9f9;
}

table tr:hover {
  background-color: #f1f1f1;
}

table th, table td {
  transition: background-color 0.3s, color 0.3s;
}

/* Media Queries */
@media (max-width: 768px) {
  main {
    padding: 10px;
  }

  h1 {
    font-size: 2em;
  }
  h2 {
    font-size: 1.5em;
  }
  h3 {
    font-size: 1.2em;
  }

  p {
    font-size: 1em;
  }
  button {
    padding: 10px 18px;
  }
}
