.ReactModal__Content {
    inset: 150px !important;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.modal-container {
    text-align: center;
}

button:disabled, button[disabled] {
  background-color: #DDD;
  color: #AAA;
}

.button-container {
    margin: 10px auto 30px auto;
    text-align: center;
}

.table-container {
    width: 100%;
    overflow-x: auto;
}

table {
    width: 100%;
    border-collapse: collapse;
}

.input-currency-right {
    position: relative;
    text-align:end;
}

.input-currency-right:before {
    position: absolute;
    top: 0;
    content:"BRC";
    right: 30px;
}

@media (max-width: 768px) {
    .table-container {
        max-width: 300px;
    }

    .ReactModal__Content {
        inset: 40px !important;
    }
}