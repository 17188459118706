.calculator-container {
    max-width: 800px;
    margin: 50px auto;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    border-radius: 8px;
    background: #FFF;
}
.header {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}
.header h2 {
    font-size: 24px;
    margin-left: 10px;
}
.tab-list {
    display: flex;
    border-bottom: 1px solid #ccc;
    margin-bottom: 20px;
}
.tab {
    padding: 10px 20px;
    cursor: pointer;
    border: none;
    background: none;
    font-size: 16px;
    color: #333;
}
.tab.active {
    border-bottom: 2px solid #4A90E2;
}
.calculator {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
}
.input-row {
    display: flex;
    width: 100%;
    gap: 10px;
    align-items: flex-end; /* Align items to the bottom */
}
.input-group {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.input-group label {
    margin-bottom: 5px;
}
.input-group input, .input-group select {
    width: 200px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    height: 40px;
}
.swap-button {
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    width: 60px;
    text-align: center;
}
.result {
    width: 100%;
    text-align: center;
    margin-top: 20px;
}
.result .amount {
    font-size: 18px;
    font-weight: bold;
}
.result .converted {
    font-size: 24px;
    color: #4A90E2;
    font-weight: bold;
}
.rates-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 10px;
}
.rate-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
}
.currency-icon {
    width: 24px;
    height: 24px;
    margin-right: 5px;
    vertical-align: middle;
}
.calculator {
    display: flex;
    flex-direction: row;
    gap: 15px;
}

  
.select-wrapper {
    position: relative;
}
  
.select-wrapper select {
    padding: 10px 10px 10px 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 0 5px 5px 0;
    appearance: none;
    background-color: white;
}
  
.currency-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    pointer-events: none;
}

.rates-icon {
    position: relative;
    left: 30px;
}

.currency-name {
    margin-left: 30px;
}


@media (max-width: 1100px) {
    .calculator {
        display: flex;
        flex-direction: column;
    }
}

@media (max-width: 768px) {
    .input-row {
        flex-direction: column;
        align-items: center !important;
        justify-content: center !important;
    }

    .react-tabs__tab-list {
        font-size: 0.8em;
    }
}