/* src/Footer.css */
.footer {
  background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 1));
  padding: 30px 0;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 30px;
  max-width: 1300px;
  margin: 0px auto;
}

.footer-logo {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.footer-logo-tagline {
  font-size: 0.75em;
}

.footer-links {
  flex: 2;
  display: flex;
  justify-content: space-around;
}

.footer-column {
  display: flex;
  flex-direction: column;
  align-items: left;
  text-align: left;
  padding: 0px 25px;
}

.footer-column h4 {
  margin-bottom: 10px;
}

.footer-column ul {
  list-style: none;
  padding: 0;
  text-align: left;
}

.footer-column li {
  margin-bottom: 25px;
}

.footer-column a:hover {
  text-decoration: underline;
}

.footer-logo-text {
  font-family: 'Inter', sans-serif;
  font-weight: 900;
  margin: 15px auto;
}

.footer-copyright {
  text-align: center;
}

@media (max-width: 768px) {

  .footer-content {
    flex-direction: column;
  }

  .footer-column {
    padding: 0px 10px;
  }

  .footer-logo-text {
    font-size: 0.6em;
  }

  .footer-logo-tagline {
    display: none;
  }
  
  .footer-copyright {
    font-size: 0.7em;
  }
}